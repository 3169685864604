import React from 'react'
import { Box } from '@components/UI'
import { PositionProps } from 'styled-system'
import styled from 'styled-components'

import DesktopNavBar from './DesktopNavBar'
import MobileNavBar from './MobileNavBar'
import { MenuLinksType } from './types'

const Container = styled(Box).attrs({
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
  height: '100%'
})``

type NavBarProps = {
  menuLinks: MenuLinksType
  socialLinks: MenuLinksType
  goBackTo?: string
  transparent?: boolean
} & PositionProps

const NavBar = ({
  menuLinks,
  socialLinks,
  position = 'sticky',
  transparent,
  goBackTo
}: NavBarProps) => (
  <Container position={position}>
    <MobileNavBar
      menuLinks={menuLinks}
      socialLinks={socialLinks}
      goBackTo={goBackTo}
      transparent={transparent}
    />
    <DesktopNavBar
      menuLinks={menuLinks}
      socialLinks={socialLinks}
      goBackTo={goBackTo}
      transparent={transparent}
    />
  </Container>
)

export default NavBar
