import React from 'react'

import { Span } from '@components/UI'
import { LinkText } from './styled'

export default ({ color }: { color: string }) => (
  <LinkText color={color} activeColor={color} to="/">
    SANTI
    <Span color="primary">&</Span>
    TUĞÇE
  </LinkText>
)
