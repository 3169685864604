import { GLink, Flex, Box } from '@components/UI'
import styled from 'styled-components'
import { MdKeyboardArrowLeft } from 'react-icons/md'

export const BarContainer = styled(Flex).attrs(
  ({ transparent }: { transparent: boolean }) => ({
    bg: transparent ? 'rgba(0,0,0,0.5)' : 'white',
    borderColor: transparent ? 'rgba(0,0,0,0)' : 'gray',
    borderWidth: 0,
    borderTop: 0,
    borderX: 0,
    borderStyle: 'solid',
    justifyContent: 'space-between',
    p: [1, 1, 1, 2],
    pr: [1, 1, 1, 3]
  })
)``

export const LinkBox = styled(Box).attrs({
  p: [3, 3, 3, 2],
  px: [4, 4, 4, 3]
})``

export const ArrowLeft = styled(MdKeyboardArrowLeft).attrs({
  viewBox: '0 0 17 17'
})`
  width: 17px;
  height: 17px;
`

export const LogoBox = styled(Box).attrs({
  pt: ['9px', '9px', '9px', 2],
  px: [3, 3, 3]
})``

export const LinkText = styled(GLink).attrs({
  color: 'black',
  activeColor: 'primary',
  fontFamily: 'sans',
  fontWeight: 'normal',
  fontSize: 1,
  textDecoration: 'none',
  textTransform: 'uppercase'
})``

// STODONEXT position fixed only on home screen
export const MenuContainerMobile = styled(Flex).attrs({
  pl: 2,
  bg: 'white',
  flexDirection: 'column',
  borderWidth: ({ open }: { open: boolean }) => (open ? '1px' : '0px'),
  borderTop: 0,
  borderX: 0,
  borderStyle: 'solid',
  borderColor: 'gray'
})`
  &:first-child {
    margin-top: 10px
    padding-top: 50px
    background-color: #999900
  };
  width: 100%;
  overflow: hidden;
  height: ${props => (props.open ? '436px' : 0)};
  transition: height 0.5s;
`
