import React, { Component } from 'react'
import _ from 'lodash'
import { DisplayProps } from 'styled-system'

import { Flex, GLink, Box } from '@components/UI'
import { Icons } from '@components/Social'
import MobileMenuButton from './MobileMenuButton'
import Logo from './Logo'
import {
  LinkBox,
  LinkText,
  LogoBox,
  MenuContainerMobile,
  BarContainer,
  ArrowLeft
} from './styled'
import { MenuLinksType, MenuLinkType } from './types'

const SocialLinks = ({ socialLinks }: { socialLinks: MenuLinksType }) => {
  const links: Record<string, string> = {}
  _.each(
    socialLinks,
    ({ name, link }: MenuLinkType) => (links[`fa_${name}`] = link)
  )

  return (
    <Icons
      iconColor="black"
      iconWidth="1em"
      pl={3}
      pt={3}
      spaceBetween={3}
      ml={3}
      links={links}
    />
  )
}

/** Foldable menu */
const Menu = ({
  links,
  socialLinks,
  open
}: {
  links: MenuLinksType
  socialLinks: MenuLinksType
  open: boolean
}) => (
  <MenuContainerMobile open={open}>
    <Box pt={2} />
    {_.map(links, ({ name, link }) => (
      <LinkText to={link} key={name}>
        <LinkBox>{name}</LinkBox>
      </LinkText>
    ))}
    <SocialLinks socialLinks={socialLinks} />
  </MenuContainerMobile>
)

// STODO close navbar when clicking on the page we're already in. For example,
// we're in Music page and clik on Music, navbar doesn't close
class MobileNavBar extends Component<
  {
    menuLinks: MenuLinksType
    socialLinks: MenuLinksType
    goBackTo?: string
    transparent?: boolean
  } & DisplayProps,
  { menuOpen: boolean }
> {
  state = {
    menuOpen: false
  }

  toggleMenu = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))
  }

  render() {
    const {
      display,
      menuLinks,
      socialLinks,
      goBackTo,
      transparent
    } = this.props
    const { menuOpen } = this.state

    const isTransparent = transparent && !menuOpen

    return (
      <Flex flexDirection="column" display={['flex', 'flex', 'flex', 'none']}>
        <BarContainer transparent={isTransparent}>
          {goBackTo ? (
            <Box pt="9px" pb="10px" pl={2}>
              <GLink font="sans" fontSize={3} color="black" to={goBackTo}>
                <ArrowLeft />
              </GLink>
            </Box>
          ) : (
            <LogoBox>
              <Logo color={isTransparent ? 'white' : 'black'} />
            </LogoBox>
          )}
          <MobileMenuButton
            onClick={this.toggleMenu}
            transparent={isTransparent}
          />
        </BarContainer>
        <Menu links={menuLinks} socialLinks={socialLinks} open={menuOpen} />
      </Flex>
    )
  }
}

export default MobileNavBar
