import * as React from 'react'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import NavBar from '@components/NavBar'
import { Box, Card } from '@components/UI'

const Content = styled(Card).attrs(
  ({ fullScreen, mt }: { fullScreen: boolean; mt: string | number }) => {
    let mtVal: string | number = 4
    if (fullScreen) mtVal = 0
    else if (!_.isNil(mt)) mtVal = mt

    return {
      p: fullScreen ? 0 : [1, 2, 3],
      // px: fullScreen ? 0 : [1, 2, 3],
      // pb: '0px',
      mt: mtVal,
      borderWidth: 0,
      style: fullScreen
        ? {}
        : {
            maxWidth: '935px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }
    }
  }
)``

type LayoutType = React.FunctionComponent<{
  fullScreen?: boolean
  mt?: number | string
  goBackTo?: string
  transparent: boolean
  title: string
}>

const Layout: LayoutType = ({
  children,
  fullScreen,
  mt,
  goBackTo,
  transparent,
  title
}) => {
  type QueryType = {
    site: {
      siteMetadata: {
        menuLinks: {
          name: string
          link: string
        }[]
      }
    }
  }

  const siteData: QueryType = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
          socialLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <Box backgroundColor={['white', 'light']} style={{ minHeight: '100vh' }}>
      <Helmet>
        <title>{`Santi & Tuğçe | ${title || ''}`}</title>
        <meta
          name="description"
          content="The music of Santi & Tuğçe is an endless journey of sound, guided by different influences: soulful melodies, colorful instrumentation, dreamy harmonies and surgically engineered dance-floor beats."
        />
      </Helmet>

      <NavBar
        position={fullScreen ? 'absolute' : 'sticky'}
        menuLinks={siteData.site.siteMetadata.menuLinks}
        socialLinks={siteData.site.siteMetadata.socialLinks}
        goBackTo={goBackTo}
        transparent={transparent}
      />
      <Content fullScreen={fullScreen} mt={mt}>
        {children}
      </Content>
    </Box>
  )
}

export default Layout
