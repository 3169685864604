import React from 'react'
import _ from 'lodash'
import { DisplayProps } from 'styled-system'

import { Flex, Box, GLink } from '@components/UI'
import { Icons } from '@components/Social'
import Logo from './Logo'
import { MenuLinksType, MenuLinkType } from './types'
import { LinkBox, LinkText, BarContainer, ArrowLeft } from './styled'

const MenuLinks = ({
  menuLinks,
  transparent
}: {
  menuLinks: MenuLinksType
  transparent: boolean
}) => (
  <Flex>
    {_.map(menuLinks, ({ name, link }) => (
      <LinkBox key={name}>
        <LinkText color={transparent ? 'white' : 'black'} to={link}>
          {name}
        </LinkText>
      </LinkBox>
    ))}
  </Flex>
)

const SocialLinks = ({
  socialLinks,
  transparent
}: {
  socialLinks: MenuLinksType
  transparent: boolean
}) => {
  const links: Record<string, string> = {}
  _.each(
    socialLinks,
    ({ name, link }: MenuLinkType) => (links[`fa_${name}`] = link)
  )

  return (
    <Icons
      iconWidth="1em"
      iconColor={transparent ? 'white' : 'black'}
      pt={2}
      spaceBetween={2}
      ml={3}
      links={links}
    />
  )
}

type DesktopNavBarProps = DisplayProps & {
  menuLinks: MenuLinksType
  socialLinks: MenuLinksType
  goBackTo?: string
  transparent?: boolean
}

const DesktopNavBar = ({
  menuLinks,
  socialLinks,
  goBackTo,
  transparent
}: DesktopNavBarProps) => (
  <BarContainer
    display={['none', 'none', 'none', 'flex']}
    transparent={transparent}
  >
    {goBackTo ? (
      <Box pt={1} pl={1}>
        <GLink font="sans" fontSize={3} color="black" to={goBackTo}>
          <ArrowLeft />
        </GLink>
      </Box>
    ) : (
      <LinkBox>
        <Logo color={transparent ? 'white' : 'black'} />
      </LinkBox>
    )}
    <Flex>
      <MenuLinks menuLinks={menuLinks} transparent={transparent} />
      <SocialLinks socialLinks={socialLinks} transparent={transparent} />
    </Flex>
  </BarContainer>
)

export default DesktopNavBar
