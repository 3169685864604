import React from 'react'
import { Button, ButtonProps } from '@components/UI'
import { color } from 'styled-system'
import styled from 'styled-components'

// @ts-ignore svg import not working with typscript
import MenuIconSvg from './svg/MobileMenuIcon.svg'

// STODO find a way to add transparent to type of MenuButton
const MenuButton = styled(Button).attrs(({ transparent }) => ({
  bg: transparent ? 'transparent' : 'white',
  pt: 2,
  pb: 1,
  borderWidth: 0
}))`
  :focus {
    outline: 0;
  }
`

const MenuIcon = styled(MenuIconSvg)`
  ${color}
`

const MobileMenuButton = ({
  onClick,
  transparent
}: { transparent?: boolean } & ButtonProps) => (
  <MenuButton onClick={onClick} transparent={transparent}>
    <MenuIcon color={transparent ? 'white' : 'black'} />
  </MenuButton>
)

export default MobileMenuButton
